/* eslint-disable brace-style */
import { CommonModule, NgClass } from "@angular/common";
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, forwardRef, model } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator,
    Validators
} from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DateTime } from "luxon";
import { CalendarModule } from "primeng/calendar";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
    standalone: true,
    selector: "app-date-time-picker",
    templateUrl: "./date-time-picker.component.html",
    styleUrls: ["./date-time-picker.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateTimePickerComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => DateTimePickerComponent)
        }
    ],
    imports: [CommonModule, ReactiveFormsModule, CalendarModule, FormsModule]
})
export class DateTimePickerComponent
    extends AppComponentBase
    implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    @Input() formControlName!: string;
    @Input() displaySeconds = false;
    @Input() defaultDate!: DateTime;
    @Input() inputClass!: NgClass;
    @Input() displayDate = false;
    @Input() displayIcon = true;
    @Input() displayLabel = false;
    @Input() displayError = false;
    @Output() inputBlur: EventEmitter<void> = new EventEmitter();
    @Output() inputFocusChange: EventEmitter<void> = new EventEmitter();
    @Output() inputKeyupEscape: EventEmitter<void> = new EventEmitter();

    private readonly unsubscribeSubject$ = new Subject<void>();

    onChange: (_: Date | null) => void = () => { };
    onTouched: () => void = () => { };

    selectedDate = model<Date>(null);
    datePickerForm: FormGroup<DatePickerForm>;

    get dateControl(): FormControl<string | null> {
        return this.datePickerForm.controls.date;
    }

    get timeControl(): FormControl<string | null> {
        return this.datePickerForm.controls.time;
    }

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder
    ) {
        super(injector);

        this.datePickerForm = this.formBuilder.group<DatePickerForm>({
            date: new FormControl<string | null>(null, Validators.required),
            time: new FormControl<string | null>(null, Validators.required)
        });
    }

    ngOnInit(): void {
        this.datePickerForm.controls.date.valueChanges
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                tap((value) => this.selectedDate.set(this.parseDate(value))),
                tap(() => this.onChange(this.getSelectedDate()))
            )
            .subscribe();

        this.datePickerForm.controls.time.valueChanges
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                tap(() => this.onChange(this.getSelectedDate()))
            )
            .subscribe();
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    private parseDate(date: string): Date {
        return DateTime.fromISO(date, { locale: 'UTC' }).toJSDate();
    }

    private getDateString(date: Date): string {
        return DateTime.fromJSDate(date).toISODate();
    }

    private getSelectedDate(): Date | null {
        const selectedDate = this.parseDate(this.dateControl.value);
        const selectedTime = this.timeControl.value;
        if (!selectedDate || !selectedTime) {
            return null;
        }

        const values = selectedTime.split(":");
        selectedDate.setHours(parseInt(values[0]));
        selectedDate.setMinutes(parseInt(values[1]));

        if (this.displaySeconds && values.length > 2) {
            selectedDate.setSeconds(parseInt(values[2]));
        } else {
            selectedDate.setSeconds(0);
        }
        return selectedDate;
    }

    private getTimeString(time: Date): string {
        if (this.displaySeconds) {
            return `${time.getHours().toString().padStart(2, "0")}:${time
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${time.getSeconds().toString().padStart(2, "0")}`;
        } else {
            return `${time.getHours().toString().padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}`;
        }
    }

    writeValue(date: Date): void {
        this.selectedDate.set(date ?? this.defaultDate?.toJSDate());
        if (date) {
            this.datePickerForm.patchValue({ date: this.getDateString(date), time: this.getTimeString(date) }, { emitEvent: false });
        } else if (date === undefined) {
            this.datePickerForm.patchValue(
                { date: this.getDateString(this.defaultDate.toJSDate()), time: undefined },
                { emitEvent: false }
            );
        }
    }

    registerOnChange(onChange: (_: Date | null) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(disabled: boolean): void {
        if (disabled) {
            this.datePickerForm.disable();
        } else {
            this.datePickerForm.enable();
        }
    }

    onDateSelected(value: Date): void {
        this.dateControl.patchValue(this.getDateString(value));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validate(control: AbstractControl): ValidationErrors {
        if (this.datePickerForm.valid) {
            return {};
        }
        let errors: ValidationErrors = {};

        errors = this.addControlErrors(errors, "date");
        errors = this.addControlErrors(errors, "time");

        return errors;
    }

    private addControlErrors(allErrors: ValidationErrors, controlName: string): ValidationErrors {
        const errors = { ...allErrors };

        const controlErrors = this.datePickerForm.get(controlName)?.errors;

        if (controlErrors) {
            errors[this.formControlName] = controlErrors;
        }

        return errors;
    }
}

export interface DatePickerForm {
    date: FormControl<string | null>;
    time: FormControl<string | null>;
}
