import { AfterViewInit, Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
    selector: "[buttonBusy]"
})
export class ButtonBusyDirective implements OnInit, AfterViewInit {
    private _originalButtonInnerHtml: any;
    private _button: any;

    constructor(private _element: ElementRef) { }

    @Input() set buttonBusy(isBusy: boolean) {
        this.refreshState(isBusy);
    }
    @Input() buttonBusyColor: string = "text-white";

    ngOnInit(): void {
        this._button = this._element.nativeElement;
        this._button.classList.add("position-relative");
    }

    ngAfterViewInit(): void {
        this._originalButtonInnerHtml = this._button.innerHTML;
    }

    refreshState(isBusy: boolean): void {
        if (!this._button) {
            return;
        }

        if (isBusy) {
            // disable button
            this._button.setAttribute("disabled", "disabled");

            this._button.innerHTML =
                '<span class="spinner-border spinner-border-sm ' + this.buttonBusyColor + '"></span>' +
                '<span style="visibility:hidden; position: absolute">' +
                this._originalButtonInnerHtml +
                "</span>";

            this._button.setAttribute("_disabledBefore", true);
        } else {
            if (!this._button.getAttribute("_disabledBefore")) {
                return;
            }

            // enable button
            this._button.removeAttribute("disabled");
            this._button.innerHTML = this._originalButtonInnerHtml;
        }
    }
}
