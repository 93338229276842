<div
    [class.subscription-bar-visible]="subscriptionStatusBarVisible()"
    class="d-flex flex-column flex-root"
    [class.timer-expanded]="timerExpanded()"
    [ngStyle]="{ '--page-bottom': timerExpanded() ? miniTimerHeight : '0' }"
>
    <default-layout *ngIf="theme == 'default'"></default-layout>
    <theme2-layout *ngIf="theme == 'theme2'"></theme2-layout>
    <theme3-layout *ngIf="theme == 'theme3'"></theme3-layout>
    <theme4-layout *ngIf="theme == 'theme4'"></theme4-layout>
    <theme5-layout *ngIf="theme == 'theme5'"></theme5-layout>
    <theme6-layout *ngIf="theme == 'theme6'"></theme6-layout>
    <theme7-layout *ngIf="theme == 'theme7'"></theme7-layout>
    <theme8-layout class="d-flex flex-column flex-row-fluid" *ngIf="theme == 'theme8'"></theme8-layout>
    <theme9-layout *ngIf="theme == 'theme9'"></theme9-layout>
    <theme10-layout class="d-flex flex-column flex-row-fluid" *ngIf="theme == 'theme10'"></theme10-layout>
    <theme11-layout *ngIf="theme == 'theme11'"></theme11-layout>
    <theme12-layout class="d-flex flex-column flex-row-fluid" *ngIf="theme == 'theme12'"></theme12-layout>
    <theme13-layout class="d-flex flex-column flex-row-fluid" *ngIf="theme == 'theme13'"></theme13-layout>
    <voqaratheme1-layout *ngIf="theme == 'voqaratheme1'"></voqaratheme1-layout>
</div>

<kt-scroll-top [ngStyle]="{ '--page-bottom': timerExpanded() ? miniTimerHeight : '0' }"></kt-scroll-top>

<linkedAccountsModal
    *ngIf="!installationMode"
    #linkedAccountsModal
    (modalClose)="getRecentlyLinkedUsers()"
></linkedAccountsModal>
<userDelegationsModal #userDelegationsModal></userDelegationsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<staffMemberSettingsModal #staffMemberSettingsModal (modalSave)="onMySettingsModalSaved()"></staffMemberSettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<commonLookupModal #userLookupModal (itemSelected)="chatBarComponent.addFriendSelected($event)"></commonLookupModal>
<chat-bar #chatBarComponent *ngIf="!installationMode" [userLookupModal]="userLookupModal"></chat-bar>
<theme-selection-panel
    #themeSelectionPanelComponent
    *ngIf="!installationMode && isQuickThemeSelectEnabled"
></theme-selection-panel>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>
<div class="mini-timer" *ngIf="appSession.tenantId > 0" [class.expanded]="timerExpanded()">
    <app-timer-interface layout="horizontal"></app-timer-interface>
    <button type="button" class="btn btn-white toggle" (click)="toggleTimer()">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 13L11 9.46667C10.8222 9.31111 10.7333 9.1 10.7333 8.83333C10.7333 8.58889 10.8222 8.37778 11 8.2C11.1778 8 11.3889 7.9 11.6333 7.9C11.9 7.92222 12.1222 8.02222 12.3 8.2L16.3333 12.2C16.5556 12.4222 16.6667 12.6889 16.6667 13C16.6667 13.3111 16.5556 13.5778 16.3333 13.8L12.2667 17.8333C12.0889 18.0111 11.8778 18.1 11.6333 18.1C11.3889 18.1 11.1778 18 11 17.8C10.8222 17.6222 10.7333 17.4 10.7333 17.1333C10.7333 16.8889 10.8222 16.6778 11 16.5L14.5 13ZM13 0.333333C11.2444 0.333333 9.58889 0.666667 8.03333 1.33333C6.5 2 5.16667 2.9 4.03333 4.03333C2.9 5.18889 2 6.53333 1.33333 8.06667C0.666667 9.6 0.333333 11.2444 0.333333 13C0.333333 14.7556 0.666667 16.4 1.33333 17.9333C2 19.4889 2.9 20.8333 4.03333 21.9667C5.16667 23.1 6.5 24 8.03333 24.6667C9.58889 25.3333 11.2444 25.6667 13 25.6667C14.7556 25.6667 16.4 25.3333 17.9333 24.6667C19.4667 24 20.8 23.1 21.9333 21.9667C23.0889 20.8333 24 19.4889 24.6667 17.9333C25.3333 16.4 25.6667 14.7556 25.6667 13C25.6667 11.2444 25.3333 9.6 24.6667 8.06667C24 6.53333 23.0889 5.18889 21.9333 4.03333C20.8 2.9 19.4667 2 17.9333 1.33333C16.4 0.666667 14.7556 0.333333 13 0.333333ZM13 2.16667C16.0222 2.16667 18.5778 3.21111 20.6667 5.3C22.7778 7.41111 23.8333 9.97778 23.8333 13C23.8333 16.0222 22.7778 18.5778 20.6667 20.6667C18.5778 22.7778 16.0222 23.8333 13 23.8333C9.97778 23.8333 7.41111 22.7778 5.3 20.6667C3.21111 18.5778 2.16667 16.0222 2.16667 13C2.16667 9.97778 3.21111 7.41111 5.3 5.3C7.41111 3.21111 9.97778 2.16667 13 2.16667Z"
                fill="#7F8285"
            />
        </svg>
    </button>
</div>
