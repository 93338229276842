import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    EntityStatus,
    NameValueDtoOfInt32,
    ProjectSummaryModel,
    ProjectsServiceProxy
} from "@shared/service-proxies/service-proxies";
import { Table } from "primeng/table";
import { firstValueFrom, map } from "rxjs";

export interface AssignedProjectsForm {
    projects: FormArray<FormGroup<AssignedProjectDetailForm>>;
}

export interface AssignedProjectDetail {
    project?: ProjectSummaryModel;
    type?: string;
    rate?: number;
}

export interface AssignedProjectDetailForm {
    project: FormControl<ProjectSummaryModel | null>;
    client: FormControl<NameValueDtoOfInt32 | null>;
    id: FormControl<number | null>;
    type: FormControl<string | null>;
    rate: FormControl<number | null>;
}

interface FilterableProjectViewModel extends ProjectSummaryModel {
    filterField: string;
}

@Component({
    selector: "app-assigned-projects",
    templateUrl: "./assigned-projects.component.html",
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})
export class AssignedProjectsComponent extends AppComponentBase implements OnInit, OnChanges {
    @ViewChild("assignedProjectsTable") table!: Table;

    @Input() assignedProjects: ProjectSummaryModel[] = [];
    @Input() allowEdit!: boolean;
    @Input() allowRemove!: boolean;
    @Output() projectsUpdated = new EventEmitter<ProjectSummaryModel[]>();

    allProjects: ProjectSummaryModel[] = [];
    isAdding = false;
    isSaving = false;
    availableProjects: ProjectSummaryModel[] = [];

    projectsForm: FormGroup<AssignedProjectsForm>;

    get projectsControl(): FormArray<FormGroup<AssignedProjectDetailForm>> {
        return this.projectsForm.controls.projects;
    }

    get assignProjectsButtonIsDisabled(): boolean {
        return this.isAdding || this.projectsControl.length >= this.allProjects.length;
    }

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder,
        private projectsServiceProxy: ProjectsServiceProxy
    ) {
        super(injector);

        this.projectsForm = this.formBuilder.group<AssignedProjectsForm>({
            projects: this.formBuilder.array<FormGroup<AssignedProjectDetailForm>>([])
        });
    }

    async ngOnInit(): Promise<void> {
        this.allProjects = await firstValueFrom(
            this.projectsServiceProxy.getAssignedSummaryList().pipe(
                map((projects) =>
                    projects.map(
                        (project) =>
                            ({
                                ...project,
                                filterField: `${project.clientName} ${project.name}`
                            }) as FilterableProjectViewModel
                    )
                )
            )
        );
        this.availableProjects = this.getFilteredProjects();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["assignedProjects"]) {
            this.availableProjects = this.getFilteredProjects();
            this.populateProjects();
            this.isSaving = false;
        }
    }

    populateProjects(): void {
        this.projectsControl.clear();
        this.projectsForm.reset();

        for (let i = 0; i < this.assignedProjects.length; i++) {
            const project = this.assignedProjects[i];
            this.projectsForm.controls.projects.push(this.getProjectFormGroup(project));
        }
    }

    private getProjectFormGroup(project: ProjectSummaryModel): FormGroup<AssignedProjectDetailForm> {
        return this.formBuilder.group<AssignedProjectDetailForm>({
            project: new FormControl(project, Validators.required),
            client: new FormControl(NameValueDtoOfInt32.fromJS({ name: project.clientName, value: project.clientId })),
            id: new FormControl(project.id),
            type: new FormControl(null),
            rate: new FormControl(null)
        });
    }

    getFilteredProjects(selectedProjectId?: number | null): ProjectSummaryModel[] {
        return this.allProjects
            .filter(
                (p) =>
                    p.id === selectedProjectId ||
                    (p.status === EntityStatus.Active && this.assignedProjects.map((ap) => ap.id).indexOf(p.id) === -1)
            )
            .sort((a, b) => (a.name === undefined ? 1 : b.name === undefined ? -1 : a.name.localeCompare(b.name)));
    }

    onProjectSelected(rowData: FormGroup<AssignedProjectDetailForm>): void {
        const selectedProject = rowData.controls.project.value;
        this.availableProjects = this.getFilteredProjects(selectedProject?.id);

        rowData.reset({
            project: selectedProject,
            client: NameValueDtoOfInt32.fromJS({ name: selectedProject?.clientName, value: selectedProject?.clientId }),
            id: selectedProject?.id,
            type: null,
            rate: null
        });
        this.table.initRowEdit(rowData);
    }

    addProject(): void {
        this.isAdding = true;
        const addRowGroup = this.formBuilder.group<AssignedProjectDetailForm>({
            project: new FormControl(null, Validators.required),
            client: new FormControl(null),
            id: new FormControl(-1),
            type: new FormControl(null),
            rate: new FormControl(null)
        });
        this.projectsControl.push(addRowGroup);
        this.table.initRowEdit(addRowGroup);
    }

    removeProject(rowData: FormGroup<AssignedProjectDetailForm>): void {
        this.updateAssignedProjects(this.projectsForm.value?.projects
            ?.filter(p => p.id !== rowData.value.id)
            ?.map((p) => p.project));
    }

    updateAssignedProjects(projects: ProjectSummaryModel[]): void {
        this.projectsUpdated.emit(projects
            ?.sort((a, b) =>
                a?.name === undefined ? 1 : b?.name === undefined ? -1 : a.name.localeCompare(b.name)
            ) ?? ([] as Array<ProjectSummaryModel>));
        this.isSaving = true;
    }

    onRowEditInit(rowData: FormGroup<AssignedProjectDetailForm>): void {
        this.availableProjects = this.getFilteredProjects(rowData.controls.id.value);
        this.isAdding = true;
    }

    onRowEditSave(rowData: FormGroup<AssignedProjectDetailForm>): void {
        rowData.updateValueAndValidity();
        if (rowData.valid) {
            this.updateAssignedProjects(this.projectsForm.value?.projects
                ?.map((p) => p.project));
            this.table.cancelRowEdit(rowData);
            this.isAdding = false;
        }
    }

    onRowEditCancel(rowData: FormGroup<AssignedProjectDetailForm>): void {
        const rowId = rowData.get("id")?.value;
        if (rowId === null || rowId === undefined || rowId < 0) {
            this.projectsControl.removeAt(this.projectsControl.controls.indexOf(rowData));
        } else {
            this.populateProjects();
        }

        this.isAdding = false;
    }
}
