<div class="card-body" [formGroup]="projectsForm">
    <h3 class="mb-4">{{ l("Projects") }}</h3>
    <div class="table-wrapper">
        <p-table
            #assignedProjectsTable
            [rowHover]="true"
            [value]="projectsControl.controls"
            formArrayName="projects"
            dataKey="controls.project.value.id"
            editMode="row"
        >
            <ng-template pTemplate="header">
                <tr *ngIf="projectsControl.controls?.length">
                    <th style="width: 50%">Project</th>
                    <th>Client</th>
                    <th *ngIf="feature.isEnabled('App.FutureFeatures')">Type</th>
                    <th *ngIf="feature.isEnabled('App.FutureFeatures')">Rate</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="rowData" [formGroupName]="ri">
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-dropdown
                                    [filter]="true"
                                    filterBy="filterField"
                                    formControlName="project"
                                    appendTo="body"
                                    id="project"
                                    name="project"
                                    [options]="availableProjects"
                                    dataKey="id"
                                    optionLabel="name"
                                    placeholder="Select"
                                    (onChange)="onProjectSelected(rowData)"
                                >
                                    <ng-template let-project pTemplate="item">
                                        <div>{{ project.name }}</div>
                                        <small class="text-secondary">{{ project.clientName }}</small>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <a [href]="'/app/admin/projects/edit-project/' + rowData.controls.project.value?.id">
                                    {{ rowData.controls.project.value?.name }}
                                </a>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        {{ rowData.controls.client.value?.name }}
                    </td>
                    <td *ngIf="feature.isEnabled('App.FutureFeatures')">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="text" formControlName="type" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="badge rounded-pill bg-secondary text-dark">N/A</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td *ngIf="feature.isEnabled('App.FutureFeatures')">N/A</td>
                    <td class="text-end">
                        <div
                            *ngIf="(allowEdit || allowRemove) && !editing && !isAdding"
                            class="btn-group float-end"
                            dropdown
                            container="body"
                        >
                            <img
                                src="../../../../../assets/metronic/themes/voqaratheme1/css/dropdawn-dots.svg"
                                alt=""
                                dropdownToggle
                                class="cursor-pointer"
                            />
                            <ul class="dropdown-menu" *dropdownMenu>
                                <li *ngIf="allowEdit">
                                    <a class="dropdown-item" pInitEditableRow (click)="onRowEditInit(rowData)">
                                        {{ l("Edit") }}
                                    </a>
                                </li>
                                <li *ngIf="allowRemove">
                                    <a class="dropdown-item" (click)="removeProject(rowData)">
                                        {{ l("RemoveFromProject") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button
                            *ngIf="editing"
                            type="button"
                            pButton
                            icon="pi pi-check"
                            class="p-button-rounded p-button-text p-button-success mr-2"
                            (click)="onRowEditSave(rowData)"
                        ></button>
                        <button
                            *ngIf="editing"
                            type="button"
                            pButton
                            pCancelEditableRow
                            icon="pi pi-times"
                            class="p-button-rounded p-button-text p-button-danger"
                            (click)="onRowEditCancel(rowData)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-editing="editing">
                <tr>
                    <td colspan="5">
                        <div class="text-center">
                            <button
                                [buttonBusy]="isSaving"
                                [buttonBusyColor]="'text-black'"
                                [disabled]="assignProjectsButtonIsDisabled"
                                (click)="addProject()"
                                type="button"
                                class="btn btn-link text-decoration-underline"
                            >
                                {{ l("AddProject") }}
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="text-center">{{ "NoData" | localize }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
