<div class="timer-buttons-wrap" [class.small]="smallIcons">
    <div
        class="timer-buttons"
        [ngClass]="{ 'is-running': isRunning, 'has-hover': enableHover }"
        [class.hover-up]="hoverDirection === 'up'"
        [class.hover-down]="hoverDirection === 'down'"
        [class.hover-auto]="hoverDirection === 'auto'"
    >
        <button
            type="button"
            title="Start Timer"
            class="btn timer-button play"
            [class.gray-scale]="useGrayScale"
            (click)="playClick.emit()"
        ></button>
        <button type="button" title="Stop Timer" class="btn timer-button stop" (click)="stopClick.emit()"></button>
        <button
            type="button"
            title="Cancel Timer"
            class="btn timer-button cancel"
            [class.gray-scale]="useGrayScale"
            (click)="cancelClick.emit()"
        ></button>
    </div>
</div>
