<div
    class="offcanvas-sm offcanvas-bottom show timer-offcanvas"
    *ngIf="layout === 'vertical'; else horizontalLayout"
    [class.edit-mode]="editMode()"
>
    <div class="offcanvas-body">
        <div class="timer-toggle text-center d-sm-none" (panup)="onPan($event)" (pandown)="onPan($event)">
            <button type="button" class="btn btn-link p-0 d-sm-none" title="Edit" (click)="toggleEditMode()">
                <svg width="36" height="5" viewBox="0 0 36 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="5" rx="2.5" fill="#3C3C43" fill-opacity="0.3" />
                </svg>
            </button>
        </div>

        <div class="time-entry-body">
            <div class="timer-buttons">
                <app-task-progress [isRunningTimer]="true"></app-task-progress>
                <ng-container *ngTemplateOutlet="playPauseStopButton"></ng-container>
            </div>
            <div class="description">
                <ng-container *ngTemplateOutlet="description"></ng-container>
            </div>
            <div class="time-range">
                <app-time-entry-range-picker
                    *ngIf="timerService.isRunning$ | async"
                    [workday]="defaultDate"
                    isRunningTimer="true"
                    [displayDates]="timerService.isRunning$ | async"
                    [displayFloatingLabels]="true"
                    [durationFormat]="'colon'"
                    showEditDetails="true"
                ></app-time-entry-range-picker>
                <div class="duration">
                    <div class="form-floating" *ngIf="!(timerService.isRunning$ | async)">
                        <input
                            #durationInput
                            class="form-control"
                            type="text"
                            id="timer-duration"
                            value="{{ startingDuration() | timerDisplayFormat: 'colon' }}"
                            (keydown.enter)="durationInput.blur()"
                            (keyup.esc)="durationInput.blur()"
                            (focusout)="updateDuration(durationInput.value)"
                            (focus)="durationInput.select(); durationIsFocused$.next(true)"
                        />
                        <label for="timer-duration">{{ l("Duration") }}</label>
                    </div>
                </div>
            </div>
            <div class="details">
                <ng-container *ngTemplateOutlet="timerFields"></ng-container>
            </div>
            <div class="description-visual-group"></div>
        </div>
    </div>
</div>

<ng-template #horizontalLayout>
    <div class="timer-wrap horizontal">
        <ng-container *ngTemplateOutlet="playPauseStopButton"></ng-container>
        <div class="duration">{{ timerService.secondsElapsed$ | async | timerDisplayFormat: "colon" }}</div>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="timerFields"></ng-container>
    </div>
</ng-template>

<ng-template #playPauseStopButton>
    <app-play-pause-stop-button
        enableHover="true"
        [hoverDirection]="layout === 'horizontal' ? 'up' : 'auto'"
        [useGrayScale]="layout === 'vertical'"
        [isRunning]="this.timerService.isRunning$ | async"
        (playClick)="requestTimerStart()"
        (stopClick)="stopTimer()"
        (cancelClick)="cancelTimer()"
        [ngClass]="{ glowing: (timerService.isWaiting$ | async) }"
    ></app-play-pause-stop-button>
</ng-template>

<ng-template #description>
    <app-time-entry-description
        inputClass="form-control"
        [displayLabel]="layout === 'vertical'"
        placeholder="Description"
        [parentForm]="parentForm"
        (parseStart)="onDescriptionParseStart()"
        (parseComplete)="onDescriptionParsed($event)"
        (keyup.enter)="requestTimerStart()"
    ></app-time-entry-description>
</ng-template>

<ng-template #timerFields>
    <app-timer-field-details
        [parentForm]="parentForm"
        [inputClass]="layout === 'horizontal' ? 'outline outline-lg' : 'outline'"
        [layout]="layout"
        [selectedProject]="projectControl.value"
        [class.timer-fields-grid]="layout === 'vertical'"
    ></app-timer-field-details>
</ng-template>
