<div class="circle-progress-wrapper">
    <circle-progress
        *ngFor="let progressCircle of progressCircles(); let first = first"
        [percent]="progressCircle.percent"
        [radius]="25"
        [space]="-7"
        [backgroundPadding]="0"
        [backgroundStrokeWidth]="0"
        [outerStrokeWidth]="7"
        [outerStrokeColor]="progressCircle.color"
        [outerStrokeGradient]="false"
        [outerStrokeLinecap]="'butt'"
        [innerStrokeWidth]="7"
        [innerStrokeColor]="first ? '#F5F6F8' : '#00000000'"
        [responsive]="true"
        [animation]="false"
        [showTitle]="false"
        [animateTitle]="false"
        [showSubtitle]="false"
        [showBackground]="false"
        [animationDuration]="0"
        [startFromZero]="true"
        [showZeroOuterStroke]="false"
        [lazy]="false"
    ></circle-progress>
</div>
