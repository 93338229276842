import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class TimerOffcanvasStateService {
    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    restrictBodyScroll(): void {
        this.renderer.addClass(document.body, "timer-offcanvas-scroll-block");
    }

    resetBodyScroll(): void {
        this.renderer.removeClass(document.body, "timer-offcanvas-scroll-block");
    }
}
