<div
    class="wrapper date-time-control"
    [class.form-floating]="displayLabel"
    [class.is-invalid]="displayLabel && displayError"
    [class.show-calendar]="displayDate"
>
    <ng-container [formGroup]="datePickerForm">
        <input
            #timeInput
            formControlName="time"
            type="time"
            [step]="displaySeconds ? 1 : 60"
            name="time"
            [ngClass]="inputClass"
            placeholder="--:--"
            (focus)="onTouched(); inputFocusChange.emit()"
            (blur)="inputBlur.emit()"
            (keyup.enter)="timeInput.blur()"
            (keyup.esc)="inputKeyupEscape.emit(); timeInput.blur()"
        />
        <label [for]="timeControl" *ngIf="displayLabel">{{ displayLabel }}</label>
        <input
            type="date"
            class="form-control"
            formControlName="date"
            (focus)="onTouched(); inputFocusChange.emit()"
            (blur)="inputBlur.emit()"
            *ngIf="displayDate"
        />
    </ng-container>
    <p-calendar
        *ngIf="displayDate"
        appendTo="body"
        [showIcon]="displayIcon"
        hideOnDateTimeSelect="false"
        [dateFormat]="displayLabel ? 'm/d/y' : 'mm/dd'"
        [(ngModel)]="selectedDate"
        (onShow)="onTouched(); inputFocusChange.emit()"
        (onClose)="inputBlur.emit()"
        (onSelect)="onDateSelected($event)"
    ></p-calendar>
</div>
